/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from "vue"
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"
import App from "./App.vue"
import DefaultLayout from "./layouts/Default.vue"
import DefaultNotLayout from "./layouts/DefaultNot.vue"
import DashboardLayout from "./layouts/Dashboard.vue"
import DashboardRTLLayout from "./layouts/DashboardRTL.vue"
import router from "./router"
import store from "./store"
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
// import './plugins/click-away'

import "./scss/app.scss"
import "./assets/fonts/fonts.css"

const script = document.createElement("script")
script.src = "https://www.osaigc.com/assets/js/jquery.min.js"
document.head.appendChild(script)



import { http } from "./api/api"
Vue.prototype.$http = http

import { BASE_API } from "../env"
Vue.prototype.$BASE_API = BASE_API

import VMdEditor from "@kangc/v-md-editor"
import "@kangc/v-md-editor/lib/style/base-editor.css"

import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js"
import "@kangc/v-md-editor/lib/theme/style/vuepress.css"
import Prism from "prismjs"

import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index"
import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css"

import createLineNumbertPlugin from "@kangc/v-md-editor/lib/plugins/line-number/index"

import createTodoListPlugin from "@kangc/v-md-editor/lib/plugins/todo-list/index"
import "@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css"
import VueMarkdownEditor from '@kangc/v-md-editor';
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';
// import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';
// .use(createKatexPlugin())
VMdEditor.use(vuepressTheme, { Prism }).use(createMermaidPlugin()).use(createCopyCodePlugin()).use(createLineNumbertPlugin()).use(createTodoListPlugin())

Vue.use(VMdEditor)



import waterfall from "vue-waterfall2"
Vue.use(waterfall)
Vue.use(ElementUI);     //全局注入element

Vue.prototype.$confirm = Vue.prototype.$confirm
Vue.use(Antd)
Vue.config.productionTip = false

Vue.use(Antd);
// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout)
Vue.component("layout-default-not", DefaultNotLayout)
Vue.component("layout-dashboard", DashboardLayout)
Vue.component("layout-dashboard-rtl", DashboardRTLLayout)

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount("#app")
